<template>
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <van-image src="https://img.yzcdn.cn/vant/apple-1.jpg"></van-image>
        </van-swipe-item>
        <van-swipe-item>
          <van-image src="https://img.yzcdn.cn/vant/apple-1.jpg"></van-image>
        </van-swipe-item>
      </van-swipe>
      <div class="choose">
        <van-row class="product-type">
          <van-col span="5" style="font-weight: bolder;">产品类型</van-col>
          <van-col span="3">全部</van-col>
          <van-col span="4">税金贷</van-col>
          <van-col span="4">发票贷</van-col>
        </van-row>
        <van-row class="customer-addr">
          <van-col span="5" style="font-weight: bolder;">受理区域</van-col>
          <van-col span="3">全国</van-col>
          <van-col span="5">选择省份</van-col>
          <van-col span="5">选择城市</van-col>
        </van-row>
      </div>
      <div class="wrapper" style="background-color: rgb(242, 242, 242);">
        <van-skeleton title :row="3" style="margin-bottom: 15px;" :loading="loading">
          <product-card v-for="item in productList" :key="item.id" :product="item"></product-card>
        </van-skeleton>
        <van-skeleton title :row="3" style="margin-bottom: 15px;" :loading="loading"></van-skeleton>
        <van-skeleton title :row="3" style="margin-bottom: 15px;" :loading="loading"></van-skeleton>
        <van-skeleton title :row="3" style="margin-bottom: 15px;" :loading="loading"></van-skeleton>
        <van-skeleton title :row="3" :loading="loading"></van-skeleton>
      </div>
      <jzsy-tabbar></jzsy-tabbar>
    </div>
</template>

<script>
import { Swipe, SwipeItem, Row, Col, Image as VanImage, Skeleton } from 'vant';
import ProductCard from './components/ProductCard'
import ProductApi from '@/api/product'
export default {
  name: "WebappProduct",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [VanImage.name]: VanImage,
    [Skeleton.name]: Skeleton,
    [ProductCard.name]: ProductCard,
  },
  data: function () {
    return {
      loading: true,
      bgColor: {
        'background-color': '#eee'
      },
      formData: {
        entName: "",
        contactPerson: "",
        phone: "",
        gender: "",
        goodsId: "无",
        goodsTypeId: "ACCOUNT_OPEN"
      },
      enterprise: {
        enterpriseName: "",
      },
      genderName: "",
      querying: false,
      popup: {
        show: false,
        message: "",
      },
      option1: [
        { text: '全部贷款', value: 0 },
        { text: '税金贷', value: 1 },
        { text: '发票贷', value: 2 },
        { text: '抵押贷', value: 3 },
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' },
      ],
      state: {
        value1: 0,
        value2: 'a',
      },
      productList: [],
    };
  },
  watch: {
    enterprise: {
      deep: true,
      handler(newVal) {
        this.formData.entName = newVal.enterpriseName;
      },
    },
    genderName(newVal) {
      if (newVal == '先生') {
        this.formData.gender = 1;
      } else if (newVal == '女士') {
        this.formData.gender = 2;
      }
    },
  },
  computed: {
    submitBtn() {
      return this.querying ? "提交中" : "提交申请";
    },
  },
  mounted() {
    ProductApi.allProcucts().then(res => {
      this.productList = res.data
      this.loading = false
    })
  },
  methods: {
    
  },
};
</script>

<style scoped>
.submit-btn {
  margin-top: 50px;
}
.main {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
.wrapper {
  padding: 20px;
}
.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>

<style>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: 300px;
  line-height: 300px;
  text-align: center;
  background-color: #39a9ed;
}
.jzsy-dropdown-menu .van-dropdown-menu__bar {
  height: 1rem;
  line-height: 1rem;
}
.jzsy-dropdown-menu .van-dropdown-menu__title {
  color: #323233;
  font-size: 0.3rem;
  line-height: 0.4rem;
}
.choose {
  font-size: 0.3rem;
  background-color: #ffffff;
  padding: 0.45rem 0.3rem;
  /* margin-bottom: 0.15rem; */
  text-align: left;
}
.choose .customer-addr {
  margin-top: 0.25rem;
}
.van-skeleton__row, .van-skeleton__title {
  height: 0.25rem;
  background-color: #ffffff;
}
</style>