<template>
  <div class="box">
    <div class="tag">
      <div class="tag-swallowtail"></div>
      <div class="tag-title">{{tagName}}</div>
    </div>
    <div class="content">
      <div class="title">{{product.bankName}} | {{product.name}}</div>
      <div class="profile">
        <div class="profile-left">
          <div class="emphatic">{{product.referenceRate}}</div>
          <div>{{product.rateUnit}}息</div>
        </div>
        <div class="profile-right">
          <div>最高额度：{{product.maxAmount}}万元</div>
          <div>期限：{{product.lengthOfMaturity}}个月</div>
        </div>
      </div>
      <div class="detail" v-show="detailShow">
        <div class="detail-title">还款方式：{{product.repaymentMode}}</div>
        <div class="detail-title">产品代码：{{product.id}}</div>
        <div class="detail-title">申请条件：</div>
        <div class="item-list" v-html="product.requirementDescription"></div>
        <div class="detail-title">申请材料：</div>
        <div class="item-list">
          <p v-for="item in materials" :key="item">{{item}}</p>
        </div>
      </div>
    </div>
    <div class="more-btn" @click="toggleDetailShow">{{showBtnTxt}}<md-icon class="down-icon" :name="showBtnIcon"></md-icon></div>
  </div>
</template>

<script>
  export default {
    name: 'ProductCard',
    props: {
      product: Object
    },
    data() {
      return {
        detailShow: false
      }
    },
    computed: {
      showBtnTxt() {
          return this.detailShow ? '收起详情' : '查看详情'
      },
      showBtnIcon() {
          return this.detailShow ? 'arrow-up' : 'arrow-down'
      },
      tagName() {
        let name
        if (this.product) {
          if (this.product.id.startsWith('A')) {
            name = '税金贷'
          } else {
            name = '发票贷'
          }
        }
        return name
      },
      materials() {
        let list = []
        if (this.product && this.product.materials) {
          list = this.product.materials.split(';')
        }
        return list
      }
    },
    methods: {
      toggleDetailShow() {
        this.detailShow = !this.detailShow
      },
    }
  }
</script>

<style scoped>
  .box {
    width: 100%;
    min-height: 200px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
    padding-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 24px;
  }
  .tag {
    position: absolute;
    right: -2px;
    background-color: #b49aff;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
  }
  .tag-swallowtail {
    border: 17px solid;
    border-color: transparent transparent transparent #ffffff;
  }
  .tag-title {
    color: #ffffff;
    padding: 5px 5px 5px 0;
  }
  .content {
    margin: 0 30px;
  }
  .title {
    font-size: 32px;
  }
  .profile {
    display: flex;
    margin: 20px 0;
  }
  .profile-left, .profile-right {
    flex-grow: 1;
    line-height: 48px;
  }
  .emphatic {
    color: #f57500;
    font-size: 36px;
  }
  .more-btn {
    border-top: 1px solid #f2f2f2;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 42px;
    color: #b49aff;
  }
  .down-icon {
    margin-left: 20px;
  }
  .detail {
    border-top: 1px solid #f2f2f2;
    padding: 20px 0;
    line-height: 38px;
  }
  .detail-title {
    margin-top: 20px;
  }
  .item-list {
    margin-left: 1em;
  }
</style>